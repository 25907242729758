import { Link, useRouteLoaderData } from "react-router-dom";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";
import { Alert, Button, Col, Descriptions, Image, Row, Typography } from "antd";

const Company = () => {
  const { company } = useRouteLoaderData(
    "AdminLayout"
  ) as IAdminLayoutLoaderData;

  const photosData = [...company.data.attributes.photos.data];

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl">公司資訊</h1>
        <div>
          <Link to={`/company/${company.data.id}/edit`}>
            <Button type="primary">編輯</Button>
          </Link>
        </div>
      </div>

      <Button
        href={`${process.env.REACT_APP_IMITI_SITE_URL}/design-companies/${company.data.id}`}
        className="inline-block mt-[20px] text-lg"
        target="_blank"
        rel="noreferrer"
        type="link"
        color="primary"
      >
        平台連結 (更改會在一分鐘內生效)
      </Button>

      <div className="mt-[50px]">
        <Descriptions title="相片">
          <Descriptions.Item label="公司 LOGO">
            {company.data.attributes.avatar.data ? (
              <Image
                src={company.data.attributes.avatar.data.attributes.url}
                width={100}
              />
            ) : (
              <div className="mr-[30px]">
                <Alert
                  message="尚未上傳公司 LOGO"
                  description="未上傳 logo 可能會導致平台算法不顯示公司，請儘速上傳"
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="封面照片">
            {photosData.length ? (
              <Row gutter={[16, 16]}>
                {photosData
                  .sort((a, b) => a.attributes.order - b.attributes.order)
                  .map((d) => {
                    if (!d.attributes.image.data) return null;
                    return (
                      <Col key={d.attributes.image.data.attributes.hash}>
                        <Image
                          src={d.attributes.image.data.attributes.url}
                          style={{
                            objectFit: "cover",
                            height: "100px",
                            width: "100px",
                          }}
                        />
                      </Col>
                    );
                  })}
              </Row>
            ) : (
              <div className="mr-[30px]">
                <Alert
                  message="尚未上傳封面照片"
                  description="未上傳封面照片可能會導致平台算法不顯示公司，請儘速上傳"
                  type="warning"
                  showIcon
                />
              </div>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="基本資料">
          <Descriptions.Item label="公司名稱">
            {company.data.attributes.name}
          </Descriptions.Item>
          <Descriptions.Item label="客服信箱">
            {company.data.attributes.email}
          </Descriptions.Item>
          <Descriptions.Item label="客服電話">
            {company.data.attributes.phone}
          </Descriptions.Item>
          <Descriptions.Item label="公司統編">
            {company.data.attributes.taxID}
          </Descriptions.Item>
          <Descriptions.Item label="公司縣市">
            {company.data.attributes.city.data?.attributes.name}
          </Descriptions.Item>
          <Descriptions.Item label="公司地址">
            {company.data.attributes.address}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="站外連結">
          <Descriptions.Item label="公司網站">
            <Button
              type="link"
              href={company.data.attributes.site}
              target="_blank"
              rel="noreferrer"
            >
              {company.data.attributes.site}
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="facebook">
            <Typography.Link
              href={company.data.attributes.fb}
              target="_blank"
              rel="noreferrer"
            >
              {company.data.attributes.fb}
            </Typography.Link>
          </Descriptions.Item>
          <Descriptions.Item label="Line 好友連結">
            <Button
              type="link"
              href={company.data.attributes.line}
              target="_blank"
              rel="noreferrer"
            >
              {company.data.attributes.line}
            </Button>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="優化搜尋設定">
          <Descriptions.Item label="可接受最低預算">
            {company.data.attributes.minBudget}
          </Descriptions.Item>
          <Descriptions.Item label="可接案地區">
            {company.data.attributes.acceptableCities.data
              .map((d) => d.attributes.name)
              .join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="可接受案件類型">
            {company.data.attributes.acceptableHouseTypes.data
              .map((d) => d.attributes.name)
              .join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="擅長風格">
            {company.data.attributes.goodAtStyles.data
              .map((d) => d.attributes.name)
              .join(", ")}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default Company;
