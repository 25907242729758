import { Button } from "antd";
import { AuthProvider } from "types/api";
import { FacebookFilled, GoogleCircleFilled } from "@ant-design/icons";
import localStorage from "lib/localStorage";

const Login = () => {
  const handleLogin = (provider: AuthProvider) => {
    localStorage.clearAuth();
    window.location.href = `${process.env.REACT_APP_STRAPI_API_URL}/api/connect/${provider}?callback=${process.env.REACT_APP_APP_URL}/connect/${provider}/redirect`;
  };
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="w-[180px]">
        <div className="text-center mb-[30px]">
          <div className="text-2xl font-bold text-antd-primary">IMITI PRO</div>
          <div className="font-bold mt-[10px]">室內空間好夥伴</div>
        </div>
        <Button
          className="w-full"
          block
          onClick={() => handleLogin("google")}
          icon={<GoogleCircleFilled />}
          size="large"
        >
          Google 登入
        </Button>
        <Button
          className="w-full mt-[10px]"
          block
          onClick={() => handleLogin("facebook")}
          icon={<FacebookFilled />}
          size="large"
        >
          Facebook 登入
        </Button>
      </div>
    </div>
  );
};

export default Login;
