import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
  Input,
  Select,
  Row,
  Col,
} from "antd";
import { RcFile } from "antd/es/upload";
import { memo, useRef, useState } from "react";
import { IMediaCollectionFields } from "types/api";
import update from "immutability-helper";
import { useDrag, useDrop } from "react-dnd";
import { useRouteLoaderData } from "react-router-dom";
import { IAdminLayoutLoaderData } from "routes/AdminLayout";

interface IShowcaseFileExtraFields {
  photoId?: number;
  description?: string;
  spaceArea?: number;
  order?: number;
}
export type ShowcaseUploadFile = UploadFile & IShowcaseFileExtraFields;

export const strapiImageToShowcaseUploadFile = (
  image: IMediaCollectionFields & IShowcaseFileExtraFields
) => {
  return {
    uid: image.hash,
    name: image.name,
    url: image.url,
    photoId: image.photoId,
    description: image.description,
    spaceArea: image.spaceArea,
    order: image.order,
  } as ShowcaseUploadFile;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => {
      reject(error);
    };
  });

interface ICustomOnChangeArgs {
  fileList: ShowcaseUploadFile[];
}

type IImitiUploadProps = UploadProps & {
  name: string;
  onlyPhoto?: boolean;
  maxFileSize: number;
  customOnChange?: (args: ICustomOnChangeArgs) => void;
};

interface DragableUploadListItemProps {
  name: string;
  onlyPhoto?: boolean;
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  index: number;
  description?: string;
  spaceArea?: number;
  fileUrl?: string;
  moveRow: (dragIndex: any, hoverIndex: any) => void;
  changeField: (index: any, key: string, value: any) => void;
  fileListLength: number;
}

const type = "DragableUploadList";

const DragableUploadListItem = ({
  onlyPhoto,
  originNode,
  moveRow,
  changeField,
  index,
  description,
  name,
  spaceArea = 1,
}: DragableUploadListItemProps) => {
  const { spaceAreas } = useRouteLoaderData(
    "AdminLayout"
  ) as IAdminLayoutLoaderData;
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver }, drop] = useDrop({
    accept: `${type}_${name}`,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: `${type}_${name}`,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const handleDescriptionOnChange = (e: any) => {
    changeField(index, "description", e.target.value);
  };

  const handleSpaceAreaOnChange = (v: number) => {
    changeField(index, "spaceArea", v);
  };
  return (
    <div
      ref={ref}
      style={{
        cursor: "move",
      }}
      className={isOver ? "border-solid border-2 border-indigo-600" : ""}
    >
      <div className="py-[8px]">
        <div>{originNode}</div>
        {!onlyPhoto && (
          <div className="py-[20px]">
            <Row gutter={20}>
              <Col span={12}>
                <label className="block mb-[20px] ml-[10px]">空間描述</label>
                <Input
                  maxLength={30}
                  name={`file.${index}.description`}
                  value={description}
                  onChange={handleDescriptionOnChange}
                />
              </Col>
              <Col span={6}>
                <label className="block mb-[20px] ml-[10px]">空間位置</label>
                <Select
                  onChange={handleSpaceAreaOnChange}
                  value={spaceArea}
                  style={{ width: "150px" }}
                >
                  {spaceAreas.data.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.attributes.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

const MemoDragableUploadListItem = memo(
  DragableUploadListItem,
  (prevProps, nextProps) => {
    if (
      prevProps.index === nextProps.index &&
      prevProps.fileListLength === nextProps.fileListLength &&
      prevProps.fileUrl === nextProps.fileUrl &&
      prevProps.description === nextProps.description &&
      prevProps.spaceArea === nextProps.spaceArea
    ) {
      return true;
    }

    return false;
  }
);

const ShowcaseFileUpload = (props: IImitiUploadProps) => {
  const { maxFileSize, customOnChange } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<ShowcaseUploadFile[]>(
    props.defaultFileList || []
  );

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: ShowcaseUploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    setFileList((fileList) => {
      const dragRow = fileList[dragIndex];
      const newFileList = update(fileList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      if (customOnChange) {
        customOnChange({ fileList: newFileList });
      }
      return newFileList;
    });
  };

  const changeField = (index: number, key: string, value: any) => {
    setFileList((fileList) => {
      const newFileList = [...fileList];
      newFileList[index] = {
        ...newFileList[index],
        [key]: value,
      };
      if (customOnChange) {
        customOnChange({ fileList: newFileList });
      }
      return newFileList;
    });
  };

  const uploadProps: UploadProps = {
    multiple: true,
    listType: "picture",
    accept: "image/png, image/jpeg, image/jpg",
    onChange: ({ fileList: antdFileList, file, event }) => {
      setFileList((fileList) => {
        const uidMapFileList = fileList.reduce((acc, cur) => {
          const key = cur.uid;
          acc[key] = cur;
          return acc;
        }, {} as Record<string, ShowcaseUploadFile>);
        const newFileList = antdFileList.map((file) => ({
          ...file,
          description: uidMapFileList[file.uid]?.description || "",
          spaceArea: uidMapFileList[file.uid]?.spaceArea || 1,
        }));
        if (customOnChange) {
          customOnChange({ fileList: newFileList });
        }
        return newFileList;
      });
    },
    beforeUpload: async () => {
      return false;
    },
    itemRender: (originNode, file: ShowcaseUploadFile) => {
      const index = fileList.indexOf(file);
      return (
        <MemoDragableUploadListItem
          name={props.name}
          onlyPhoto={props.onlyPhoto}
          originNode={originNode}
          index={index}
          description={file.description}
          spaceArea={file.spaceArea}
          fileUrl={file.thumbUrl || file.url}
          moveRow={moveRow}
          changeField={changeField}
          fileListLength={fileList.length}
        />
      );
    },

    fileList: fileList,
    onPreview: handlePreview,
  };

  const uploadButton = <Button icon={<UploadOutlined />}>上傳照片</Button>;
  return (
    <div>
      <Upload {...uploadProps} {...props} maxCount={maxFileSize}>
        {fileList.length >= maxFileSize ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ShowcaseFileUpload;
